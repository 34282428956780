import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/HomePage.vue'),
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('../components/RegistrationPage.vue'),
  },
  {
    path: '/sponsorship',
    name: 'Sponsorship',
    component: () => import('../components/SponsorshipPage.vue'),
  }, 
  {
    path: '/fonds-ac',
    name: 'FondsAC',
    component: () => import('../components/FondsACPage.vue'),
  },
  {
    path: '/tournament',
    name: 'Tournament',
    component: () => import('../components/TournamentPage.vue'),
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: () => import('../components/ThankYouPage.vue'),
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
