<template>
  <v-app-bar app dark color="primary">
    <v-toolbar-title style="color: #ffffff;">Leopard Golf Cup</v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- Hamburger menu on small screens -->
    <v-btn icon v-if="mobile" @click="drawer = !drawer">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <!-- Buttons for larger screens -->
    <template v-if="!mobile">
      <v-btn text :to="{ name: 'Home' }" rounded prepend-icon="mdi-home-heart">Home</v-btn>
      <v-btn text :to="{ name: 'Tournament' }" rounded prepend-icon="mdi-golf">Tournament Details</v-btn>
      <v-btn text :to="{ name: 'Registration' }" rounded prepend-icon="mdi-pencil">Registration</v-btn>
      <v-btn text :to="{ name: 'Sponsorship' }" rounded prepend-icon="mdi-hand-heart-outline">Sponsors</v-btn>
      <v-btn text :to="{ name: 'FondsAC' }" rounded prepend-icon="mdi-heart-circle-outline">Fonds AC</v-btn>
    </template>
  </v-app-bar>

  <!-- Navigation Drawer for small screens -->
  <v-navigation-drawer v-model="drawer" app temporary>
    <v-list>
      <v-list-item :to="{ name: 'Home' }" @click="drawer = false">
        <v-list-item-icon>
          <v-icon>mdi-home-heart</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'Tournament' }" @click="drawer = false">
        <v-list-item-icon>
          <v-icon>mdi-golf</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Tournament Details</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'Registration' }" @click="drawer = false">
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Registration</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'Sponsorship' }" @click="drawer = false">
        <v-list-item-icon>
          <v-icon>mdi-hand-heart-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Sponsors</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'FondsAC' }" @click="drawer = false">
        <v-list-item-icon>
          <v-icon>mdi-heart-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Fonds AC</v-list-item-title>
      </v-list-item>
      
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import { ref } from 'vue'
import { useDisplay } from 'vuetify'

const { mobile } = useDisplay()

const drawer = ref(false)
</script>

<style>
.v-btn--active {
  background-color: rgb(255, 255, 255);
  color: black;
}

/* Adjustments for smaller screens, if needed */
@media (max-width: 768px) {
  /* Example adjustments for smaller screens */
}
</style>
